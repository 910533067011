import React from 'react'
import PropTypes from 'prop-types'

import CautionImgSrc from '../../assets/images/cv-status-modal/caution.svg'

import CVStatusModalStyled from './styled'

class CVStatusModal extends React.PureComponent {
  getTitleContainer() {
    let output = null
    if (this.props.title) {
      output = <div className="title">{this.props.title}</div>
    }
    return output
  }

  getMesssageContainer() {
    let output = null
    if (this.props.message) {
      output = <div className="message">{this.props.message}</div>
    }
    return output
  }

  render() {
    return (
      <CVStatusModalStyled id={this.props.id} data-test={this.props.id} className={this.props.className}>
        <div className="alert-icon-container">
          <img src={CautionImgSrc} />
        </div>
        <div className="content-container">
          {this.getTitleContainer()}
          {this.getMesssageContainer()}
        </div>
      </CVStatusModalStyled>
    )
  }
}

CVStatusModal.defaultProps = {
  id: 'cv-status-modal-component',
  className: '',
  title: '',
  message: '',
}

CVStatusModal.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default CVStatusModal
