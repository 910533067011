import styled from 'styled-components'

export default styled.div`
  display: flex;
  width: 100%;
  background: white;
  padding: 16px 24px;
  box-shadow: 0 0 3px 1px #981214;
  .alert-icon-container {
    width: 52px;
    height: 52px;
    margin-right: 16px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .content-container {
    .title {
      color: #981214;
      font-size: 19px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
    .message {
      color: #232529;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
  }
`
